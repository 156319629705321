module.exports = {
  defaultTitle: 'Resul Takak',
  logo: '',
  author: 'Resul Takak',
  url: 'https://resul.me',
  legalName: 'Resul Takak',
  defaultDescription: 'I’m Resul and I’m a Backend & Devops engineer!',
  socialLinks: {
    twitter: 'http://www.twitter.com/resultakak',
    github: 'https://github.com/resultakak',
    linkedin: 'https://www.linkedin.com/in/resultakak/',
    instagram: 'https://instagram.com/resultakak',
    youtube: 'https://www.youtube.com/user/resultakak',
    google: 'https://plus.google.com/u/0/+resultakak',
  },
  googleAnalyticsID: 'UA-88875900-4',
  themeColor: '#6b63ff',
  backgroundColor: '#6b63ff',
  social: {
    facebook: 'appId',
    twitter: '@resultakak',
  },
  address: {
    city: 'İstanbul',
    region: 'Turkey',
    country: 'Turkey',
    zipCode: '34000',
  },
  contact: {
    email: 'hi@resul.me',
    phone: 'phone number',
  },
  foundingDate: '2020',
};
